import React, { useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from './product.module.scss';
import { CartContext } from '../../store';
import Currency from 'react-currency-formatter';
import { Link } from 'react-router-dom';

const Product = props => {
	const cart = useContext(CartContext);

	const { item } = props;
	return (
		<div to={`/products/${item.id}`} className={s.product}>
			<Link to={`/products/${item.id}`}>
				<figure
					itemProp='product'
					itemScope
					itemType='https://schema.org/ImageObject'
					className={s.product__image}
				>
					<LazyLoadImage
						alt={item.name}
						src={item.image} // use normal <img> attributes as props
						width={'100%'}
					/>
					<div onClick={() => cart.addCart(item)} className={s.quickadd}>
						<div>Quick Add</div>
					</div>
				</figure>
			</Link>
			<h2 itemProp='name' className={s.product__title}>
				{props.item.name}
			</h2>
			<p className={s.product__color}>{item.color}</p>
			<p className={s.product__color}>
				<span itemProp='price' className={s.product__price}>
					{' '}
					<Currency quantity={item.price * 360} currency='NGN' />
				</span>
			</p>
		</div>
	);
};

export default Product;
