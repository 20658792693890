import React, { useState, useContext, useEffect } from 'react';
import './header.scss';
import styles from './topBar.module.scss';
import Currency from 'react-currency-formatter';
import { CartContext } from '../../store';
import { useLocation } from 'react-router-dom';

const TopBar = () => {
	const [cart, setCart] = useState(false);
	const cartContext = useContext(CartContext);
	const toggleCart = () => {
		setCart(!cart);
	};

	let location = useLocation();
	useEffect(() => {
		setCart(false);
	}, [location.pathname]);

	return (
		<>
			<nav className='top__bar'>
				<div className='container'>
					<div className={styles.menus}>
						<ul>
							<li>
								<a href='/'>create account</a>
							</li>
							<li>
								<a href='/'>sign in</a>
							</li>

							<li className={styles.cartBtn}>
								<button onClick={() => toggleCart()}>
									{cart ? 'Close' : 'Cart'}
								</button>
								<div className={styles.cartCount}>
									{cartContext.cart.length}
								</div>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<section
				className={styles.cart}
				style={{ height: cart ? '622px' : '0px', opacity: cart ? 1 : 0 }}
			>
				<div className='container'>
					<div className={styles.cart__title}>
						<div className={styles.cart__box}>
							{cartContext.cart.length} Items{' '}
						</div>
						<div className={`${styles.cart__box} ${styles.cart__cart}`}>
							Checkout
						</div>
						<div className={styles.cart__box} style={{ textAlign: 'right' }}>
							Cart Expires in
						</div>
					</div>
					<section className={styles.cart__body}>
						<div className={`${styles.cart__box}`}>
							<div>Shopping cart</div>
							<div className={`${styles.cart__box__pane}`}>
								{cartContext.cart.length > 0 ? (
									<ul className={styles.cart__items}>
										{cartContext.cart.map((item, i) => (
											<li key={i} className={styles.cart__items__list}>
												<button
													className={styles.items__close}
													onClick={() => cartContext.removeCart(i)}
												></button>

												<div className={styles.items__image}>
													<img src={item.image} alt={item.name} />
												</div>
												<div className={styles.items__content}>
													<div className={styles.items__name}>{item.name}</div>
													<div className={styles.items__size}>
														Size: {item.size}
													</div>
													<div className={styles.items__size}>
														Color: {item.color}
													</div>
												</div>
											</li>
										))}
									</ul>
								) : (
									<div className={styles.cart__emptystate}>
										You have no items in your cart.
									</div>
								)}
							</div>
						</div>

						<div className={styles.cart__box}>
							<div className={`${styles.cart__box__pane__right}`}>
								<div className={styles.cart__panel__loggedout}>
									<h3 className={styles.h3}>NEW HERE? GET STARTED</h3>
									<a href='/' className='btn btn--primary'>
										Enter billing and shipping
									</a>
								</div>
								You have no items in your cart.
								<div>
									<div className={styles.cart__list}>
										Subtotal
										<div>
											<Currency
												quantity={cartContext.totalCost * 120}
												currency='NGN'
											/>
										</div>
									</div>

									<div className={styles.cart__list}>
										Shipping to Nigeria
										<div>
											<Currency
												quantity={cartContext.totalCost * 10}
												currency='NGN'
											/>
										</div>
									</div>
									<div className={styles.cart__list}>
										Sales tax
										<div>
											<Currency
												quantity={cartContext.totalCost * 7}
												currency='NGN'
											/>
										</div>
									</div>

									<div
										className={styles.cart__list}
										style={{ fontWeight: 600 }}
									>
										Total
										<div>
											<Currency
												quantity={cartContext.totalCost * 360}
												currency='NGN'
											/>
										</div>
									</div>
								</div>
								<button
									className={styles.cart__btn}
									disabled={cartContext.cart.length >= 1 ? null : 'disabled'}
									onClick={() => alert('check out')}
								>
									Complete Order
								</button>
							</div>
						</div>
					</section>
				</div>
			</section>
		</>
	);
};

export default TopBar;
