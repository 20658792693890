import React, { lazy, Suspense } from 'react';
import styles from './home.module.scss';
import Product from '../../components/product';
import * as female from '../../data/female';

const Popular = lazy(() => import('./popular'));

const PopularLoader = () => {
	return (
		<div className={styles.popular__loading}>
			<li>
				<div></div>
			</li>
			<li>
				<div></div>
			</li>
			<li>
				<div></div>
			</li>
			<li>
				<div></div>
			</li>
			<li>
				<div></div>
			</li>
			<li>
				<div></div>
			</li>
		</div>
	);
};

function Home() {
	return (
		<>
			<section className={styles.hero}>
				<video
					src={require('../../components/videos/hero.mp4')}
					loop
					autoPlay
					muted
					playsInline
					className={styles.video}
				></video>
			</section>
			<div className={styles.hero__message}>
				Building a brand of your own? <a href='/'> Start selling on Wawooh.</a>
			</div>

			{/* <img
				src={require('../components/images/sprite_logos_grid.png')}
				alt='logo'
			/> */}
			<div className='container'>
				<section className={styles.section}>
					<h2 className={styles.section__title}>shop popular categories</h2>

					<Suspense fallback={<PopularLoader />}>
						<Popular />
					</Suspense>
				</section>
				<section className={styles.section}>shop by location</section>

				<div className={styles.product__list}>
					{female.default.map((items, i) => (
						<Product item={items} key={i} />
					))}
				</div>
			</div>
		</>
	);
}

export default Home;
