import React, { useState } from 'react';
import { BrowserRouter as Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/home';
import Product from './pages/products';
import Header from './components/header/index';
import { CartContext } from './store';
import './App.scss';
import Footer from './components/footer/index.jsx';

const App = () => {
	const carty = localStorage.getItem('cartLocalStore')
		? JSON.parse(localStorage.getItem('cartLocalStore'))
		: [];

	let [cart, setCart] = useState(carty);
	const prices = cart.map(item => item.price);

	const setTotal = function() {
		return prices.reduce((total, price) => (total += price), 0);
	};

	const addCart = product => {
		setCart(cart.concat(product));
		localStorage.setItem(
			'cartLocalStore',
			JSON.stringify(cart.concat(product))
		);
	};

	const removeCart = productID => {
		const newcart = Object.assign([], cart);
		newcart.splice(productID, 1);
		setCart(newcart);
		localStorage.setItem('cartLocalStore', JSON.stringify(newcart));
	};

	return (
		<Switch>
			<CartContext.Provider
				value={{ addCart, removeCart, cart, totalCost: setTotal() }}
			>
				<Helmet>
					<meta charSet='utf-8' />
					<title>Wawooh®</title>
					<link rel='canonical' href='http://mysite.com/example' />
				</Helmet>
				<main className='main' id='main'>
					<Header />

					<Route path='/' exact>
						<Home />
					</Route>
					<Route path='/products/:id'>
						<Product />
					</Route>
					<Route path='/about'>
						<Home />
					</Route>

					<Footer />
				</main>
			</CartContext.Provider>
		</Switch>
	);
};

export default App;
