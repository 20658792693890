import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as female from '../../data/female';
import styles from './style.module.scss';
import Sticky from 'react-sticky-el';
import Currency from 'react-currency-formatter';
import Lottie from 'lottie-react-web';
import { CartContext } from '../../store';
import { Helmet } from 'react-helmet';

import animationData from '../../components/animations/1.json';

const PlaceholderComponent = () => {
	return (
		<div
			style={{
				width: '80px',
				position: 'absolute',
				zIndex: 1,
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				margin: 'auto'
			}}
		>
			<Lottie
				options={{
					animationData: animationData
				}}
				width='80px'
			/>
		</div>
	);
};

const Product = ({ scrollPosition }) => {
	const data = female.default;
	const { id } = useParams();
	const product = data.find(item => item.id === parseInt(id));
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const cartContext = useContext(CartContext);

	return (
		<>
			{/* <header className={styles.header}></header> */}
			<Helmet>
				<title>{product.name} - Wawooh</title>
			</Helmet>
			<div className={`${styles.container} container`}>
				<div className={`${styles.imagelist}`}>
					<figure
						itemProp='product'
						itemScope
						itemType='https://schema.org/ImageObject'
						className='product__image'
					>
						<LazyLoadImage
							alt={product.name}
							src={product.image} // use normal <img> attributes as props
							width={'100%'}
						/>
						<PlaceholderComponent />
					</figure>

					{product.images
						? product.images.map((image, index) => {
								return image.video ? (
									<video
										src={image.photo}
										autoPlay
										playsInline
										muted
										loop
										className='product__video'
									></video>
								) : (
									<figure
										key={index}
										itemProp='product'
										itemScope
										itemType='https://schema.org/ImageObject'
										className='product__image'
									>
										<LazyLoadImage
											alt={index}
											src={image.photo} // use normal <img> attributes as props
											width={'100%'}
											scrollPosition={scrollPosition}
										/>
										<PlaceholderComponent />
									</figure>
								);
						  })
						: null}
				</div>

				<div className={styles.main}>
					<Sticky
						boundaryElement='.container'
						style={{ position: 'relative' }}
						hideOnBoundaryHit={false}
					>
						<div className={styles.product__details}>
							<h3 className={styles.product__name}>{product.name}</h3>
							<div className={styles.product__price}>
								<Currency quantity={product.price * 360} currency='NGN' />
							</div>
							<div className={styles.product__old__price}>
								Traditional Retail:{' '}
								<Currency quantity={product.price * 560} currency='NGN' />
							</div>
							<div className={styles.product__sizes}>
								<li>s</li>
								<li>m</li>
								<li>l</li>
								<li>xl</li>
								<li>xxl</li>

								<div className={styles.product__title}>Sizes</div>
							</div>

							<div className={styles.product__colors}>
								<li style={{ background: '#ce0f3d' }}>red</li>
								<li style={{ background: '#c9485b' }}>red</li>
								<li style={{ background: '#5f6caf' }}>red</li>
								<li style={{ background: '#2c786c' }}>red</li>
								<li style={{ background: '#333366' }}>red</li>
								<div className={styles.product__title}>Colors</div>
							</div>
							<button
								className={`${styles.product__btn} btn btn--primary`}
								onClick={() => cartContext.addCart(product)}
							>
								Add to cart
							</button>
							<section className={styles.product__info}>
								<li>Model is 5′8″Wearing size S</li>
								<li>Oversized fit</li>
								<li>
									Shell: 100% Recycled Polyester; Fill: 100% Recycled Down And
									Feathers Machine wash cold, tumble dry low
								</li>
								<li>
									Water-resistant, bluesign-approved shell fabric is made from
									18 renewed plastic bottles.
									<br />
									Recommended for 10–30F temperatures
								</li>
								<li>Made in Bac Acrra, Ghana</li>
								<div className={styles.product__info__description}>
									Description
								</div>
								<li>
									All the comfort of a sleeping bag—but considerably more
									stylish. Surprisingly lightweight for its length, our Re:Down®
									Sleeping Bag Puffer insulates your entire body from head to
									(nearly) toe. It has an insulated hood, windproof cuffs, and
									button-tab pockets, as well as contrasting black trim and a
									shiny finish. The Re:Down® fill is made from recycled down
									comforters and pillows, and the soft outer shell is made of
									100% recycled fabric with flurorine-free water-repellency.
								</li>
							</section>
						</div>
					</Sticky>
				</div>
			</div>
		</>
	);
};

export default Product;
