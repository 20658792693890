import React from 'react';
import './header.scss';
import Nav from './navigation';
import Logo from '../images/wawooh.svg';
import TopBar from './topBar';
import { BrowserRouter as Route, Link } from 'react-router-dom';

const Header = () => {
	return (
		<>
			<TopBar />
			<div className='site__logo'>
				<Link to='/'>
					<Route>
						<img src={Logo} alt='logo' width='130' style={{ margin: 'auto' }} />
					</Route>
				</Link>
			</div>

			<Nav />
		</>
	);
};

export default Header;
