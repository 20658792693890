import React from 'react';
import styles from './style.module.scss';
import Logo from '../images/wawooh.svg';
const Footer = () => {
	return (
		<footer className={styles.footer}>
			<div className={`container ${styles.footer__wrapper}`}>
				<div className={styles.footer__logo}>
					<img src={Logo} alt='logo' width='130' />
				</div>
				<nav className={styles.footer__nav}>
					<section className={styles.footer__links}>
						<ul>
							<li>About</li>
							<li>Jobs</li>
							<li>Press</li>
							<li>Blog</li>
							<li>Reviews</li>
						</ul>
						<ul>
							<li>Site</li>
							<li>Account</li>
							<li>Shop</li>
							<li>Sell</li>
						</ul>
						<ul>
							<li>Help</li>
							<li>Questions</li>
							<li>Return & Exchange</li>
							<li>Brands we accept</li>
							<li>Contact Us</li>
						</ul>
					</section>
					<div className={styles.footer__newsleter}>
						<form action='email' method='post'>
							<div className={styles.footer__newsleter__title}>
								Subscribe to recieve our daily 10% off email.
							</div>
							<input
								type='email'
								name='email'
								id=''
								placeHolder='me@email.com'
								className={styles.footer__newsleter__field}
							/>
						</form>
					</div>
				</nav>
			</div>
			<div className={styles.footer__copy}>
				A Product of LongBridge Tech. © 2020 Wawooh, Ltd. All Rights Reserved.
			</div>
		</footer>
	);
};

export default Footer;
