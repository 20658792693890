import React, { useState } from 'react';
import './header.scss';
function SearchBar() {
	const [searchTerm, setSearchTerm] = useState();
	const [showSearchMenu, setShowSearchMenu] = useState(false);

	const searchFocus = () => {
		setShowSearchMenu(!showSearchMenu);
	};
	const searchBlur = () => {
		setShowSearchMenu(!showSearchMenu);
	};

	return (
		<div className='search__bar'>
			<input
				type='search'
				name=''
				id=''
				value={searchTerm}
				onChange={e => setSearchTerm(e.target.value)}
				defaultValue=''
				placeholder='Search'
				className='search__field'
				//ref={search}
				onFocus={() => searchFocus()}
				onBlur={() => searchBlur()}
			/>

			{showSearchMenu ? <div className='search__menu'>{searchTerm}</div> : null}
		</div>
	);
}

export default SearchBar;
