import React from 'react';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import './header.scss';
import * as data from '../../data/menu.json';
import SearchBar from './searchBar';

const Navigation = () => {
	const menuData = data.default;

	return (
		<header className={'header'}>
			<div className='container'>
				<nav className='navigation'>
					<Route> </Route>
					{menuData.map((item, index) => (
						<li
							key={index}
							className={`menu__list ${
								item.primary ? 'menu__list--line' : null
							}`}
						>
							<Link to={item.path} className='link'>
								{item.title}
								{item.sub ? (
									<div className='dropmenu'>
										<ul className='menu__sub'>
											{item.sub.map((subItem, index) => (
												<li key={index} className='menu__sub__items'>
													{subItem.title}
												</li>
											))}
										</ul>
										<ul className='menu__sub'>
											{item.sub.map((subItem, index) => (
												<li key={index} className='menu__sub__items'>
													{subItem.title}
												</li>
											))}
										</ul>
										<div className='menu__image'>
											<img src={item.image} alt={item.title} />
										</div>
									</div>
								) : null}
							</Link>
						</li>
					))}

					<SearchBar />
				</nav>
			</div>
		</header>
	);
};

export default Navigation;
